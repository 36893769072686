<template>
    <div class="investment-text innerContainer">
        <h2 class="header-title luxury">{{ item.title }}</h2>
        <div class="content" v-html="item.content"></div>
        <div @click="changeRoute" class="download-container">
            <svg width="38" height="11" viewBox="0 0 38 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M31.8652 9.25479C31.5964 9.51868 31.5746 9.97032 31.8166 10.2635C32.0585 10.5568 32.4726 10.5805 32.7415 10.3166L37.6484 5.5L32.7415 0.683367C32.4726 0.419468 32.0585 0.443238 31.8166 0.736459C31.5746 1.02968 31.5964 1.48131 31.8652 1.74521L35.0279 4.84961H0.65C0.291015 4.84961 0 5.14062 0 5.49961C0 5.85859 0.291015 6.14961 0.65 6.14961H35.0287L31.8652 9.25479Z"
                      fill="#940128"/>
            </svg>
            <span class="fira title">{{ item.urlTitle }}</span>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";
import {useRouter} from "vue-router";


const props = defineProps({
    contentData: {
        type: Object,
    },
});
let router = useRouter()
let title = computed(() => props.contentData?.conf?.header?.title)
let item = computed(() => {
    let item = props.contentData.data?.list?.[0]

    return {
        ...item,
        mobile: item?.images?.[0]?.devices?.mobile,
        desktop: item?.images?.[0]?.devices?.desktop,
        tablet: item?.images?.[0]?.devices?.tablet,
        urlTitle: item.url?.title,
        url: item.url?.url,
    }
})

const changeRoute = () => {
    router.push({
        path: item.value.url,
    })
}
</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.11, 0, .21, .98);
.investment-text:deep {
    color: $primaryNavy;
    margin-top: 89px;

    .header-title {
        font-style: normal;
        font-weight: 400;
        font-size: 55px;
        text-transform: uppercase;
        @media (max-width: 1650px) {
            font-size: 34px;
        }
    }

    .content {
        @media (max-width: 1023px) {
            margin-top: 34px;
        }

        ul {
            list-style: none;
            margin-top: 21px;
        }

        li, h2, h1, h3 {
            font-family: 'FiraGO';
            font-style: normal;
            font-weight: 400;
            font-size: 21px;
            line-height: 25px;

            &::before {
                content: "\2022";
                color: $primaryNavy;
                font-weight: bold;
                display: inline-block;
                font-size: 30px;
                width: 20px;
                font-family: 'FiraGO';
            }
        }

        em {
            font-family: 'FiraGO';
            font-style: normal;
            font-weight: 400;
            font-size: 21px;
            line-height: 25px;
        }

        p {
            margin-top: 5px;
            font-family: 'FiraGO';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
        }

    }

    .download-container {
        display: flex;
        align-items: center;
        margin-top: 21px;
        cursor: pointer;

        .title {
            margin-left: 13px;
            font-weight: 700;
            font-size: 13px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
        }
    }
}
</style>
<style lang="scss">
body {
    &.is-dark {
        .loyalty-text {
            color: white;
        }

        .header-title, .title {
            color: white;

        }

        .content {
            color: white;

            li, h2, h1, h3 {
                color: white;

                &::before {
                    color: white !important;
                }
            }
        }
    }
}

</style>
